import React from 'react';
import Grocery from '@material-ui/icons/ShoppingCart';
import Petrol from '@material-ui/icons/LocalGasStation';
import Clothes from '@material-ui/icons/LocalOffer';
import Diy from '@material-ui/icons/LocalFlorist';
import Hotels from '@material-ui/icons/RestaurantMenu';
import Household from '@material-ui/icons/LocalLaundryService';
import Holidays from '@material-ui/icons/Flight';
import Health from '@material-ui/icons/Favorite';
import Gifts from '@material-ui/icons/Cake';
import styles from './styles';

export const form = [
  {
    title: 'Groceries',
    icon: <Grocery style={styles.challenge.icon} />,
    key: 'grocery',
  },
  {
    title: 'Petrol & Diesel',
    icon: <Petrol style={styles.challenge.icon} />,
    key: 'petrol',
  },
  {
    title: 'Clothes & shoes',
    icon: <Clothes style={styles.challenge.icon} />,
    key: 'clothes',
  },
  {
    title: 'Health & Beauty',
    icon: <Health style={styles.challenge.icon} />,
    key: 'health',
  },
  {
    title: 'Household & Electrical Goods',
    icon: <Household style={styles.challenge.icon} />,
    key: 'household',
  },
  {
    title: 'DIY & Gardening',
    icon: <Diy style={styles.challenge.icon} />,
    key: 'diy',
  },
  {
    title: 'Restaurants & Hotels',
    icon: <Hotels style={styles.challenge.icon} />,
    key: 'hotels',
  },
  {
    title: 'Holidays & Days Out',
    icon: <Holidays style={styles.challenge.icon} />,
    key: 'holidays',
  },
  {
    title: 'Gifts for family & friends',
    icon: <Gifts style={styles.challenge.icon} />,
    key: 'gifts',
  },
];
