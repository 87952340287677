import React from 'react';
import Radium from 'radium';
import Typography from '@material-ui/core/Typography';

import createStyles from 'modules/Shared/createJssStyles';
import SectionCentered from 'modules/layout/SectionCentered';
import TextField from 'modules/Shared/TextField';
import { formatPrice } from 'redux/utils/formatter';
import LoadingIndicator from 'modules/Shared/LoadingIndicator';
import { form } from './form';
import styles from './styles';
import Copy from './Copy';

const classes = createStyles(styles);

const RenderFormRow = ({
  title,
  icon,
  value,
  id,
  bgColor,
  updateField,
  makeRequest,
  onUpdateField,
}) => {
  let sanitisedValue = value;
  if (typeof value === 'string') {
    sanitisedValue = value.replace(/[^0-9]/g, '');
  }

  return (
    <div style={bgColor}>
      <span style={styles.formRow.SectionTitle}>
        {icon}
        <Typography classes={{ root: classes.formColumn }}>{title}</Typography>
      </span>
      <TextField
        fullWidth={false}
        onChange={onUpdateField(updateField, id, makeRequest)}
        value={sanitisedValue}
        placeholder="0"
        id={id}
        textFieldClasses={{
          label: classes.hintStyle,
          input: classes.inputStyle,
          form: classes.TextField,
        }}
        margin="none"
      />
    </div>
  );
};

const Loader = ({ loading, savings }) => {
  if (loading)
    return (
      <div style={styles.loadContainer}>
        <LoadingIndicator loading />
      </div>
    );
  return (
    <span style={styles.savingsAmount}>
      {savings.min && `${formatPrice(savings.min)} - `}
      {formatPrice(savings.max)}
    </span>
  );
};

class HowMuch extends React.Component {
  onUpdateField = (updateField, field, makeRequest) => ({
    target: { value },
  }) => {
    if (value === '') return updateField(field, '');
    if (/^\d*$/.test(value)) {
      const parsed = parseInt(value, 10);
      if (parsed > 99999) return;
      updateField(field, parsed);
      makeRequest();
    }
  };

  render() {
    const { updateField, makeRequest, fields, loading, savings } = this.props;

    return (
      <SectionCentered style={styles.base}>
        <Typography
          variant="h3"
          component="h2"
          style={styles.pageTitle}
          color="primary"
          gutterBottom
        >
          The cashback challenge
        </Typography>
        <Typography style={styles.pageSubTitle} color="textSecondary">
          {Copy.estimated}
        </Typography>
        <div style={styles.challenge}>
          <div style={styles.challenge.inputSection}>
            <div>
              {form.map((formList, i) => {
                return (
                  <RenderFormRow
                    key={formList.key}
                    icon={formList.icon}
                    title={formList.title}
                    bgColor={i % 2 === 0 ? styles.formRow : styles.formRowGrey}
                    value={fields[formList.key]}
                    id={formList.key}
                    updateField={updateField}
                    makeRequest={makeRequest}
                    onUpdateField={this.onUpdateField}
                  />
                );
              })}
            </div>
          </div>
          <div style={styles.challenge.savingSection}>
            <div style={styles.challenge.savingSection.holder}>
              <h3 style={styles.challenge.savingSection.holder.title}>
                {Copy.savingsTitle}
              </h3>
              <h2 style={styles.challenge.savingSection.holder.saving}>
                {!savings ? (
                  <span style={styles.savingsAmount}>£120 - £456</span>
                ) : (
                  <Loader loading={loading} savings={savings} />
                )}
              </h2>
              <p style={styles.challenge.savingSection.holder.text}>
                {Copy.savings}
              </p>
            </div>
          </div>
        </div>
      </SectionCentered>
    );
  }
}

export default Radium(HowMuch);
