import gstyles from 'constants/styles.js';
import colors from 'constants/colors.js';
import media from 'constants/media.js';

const formRow = {
  padding: 18,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export default {
  savingsAmount: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
  },
  base: {
    backgroundColor: colors.secondary.white,
  },
  pageTitle: {
    marginTop: 32,
  },
  pageSubTitle: {
    marginBottom: 32,
  },
  formRowGrey: {
    ...formRow,
    backgroundColor: colors.secondary.lightGrey,
  },
  inputStyle: {
    textAlign: 'center',
    ...gstyles.fonts.p4,
    color: `${colors.primary.DarkPurple} !important`,
    alignItems: 'center',
  },
  hintStyle: {
    textAlign: 'center',
    ...gstyles.fonts.p4,
    color: colors.secondary.grey,
    alignItems: 'center',
    left: 0,
    right: 0,
  },
  TextField: {
    width: '20%',
  },
  formRow: {
    ...formRow,
    SectionTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexGrow: 1,
    },
  },
  loadContainer: {
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formColumn: {
    color: colors.pageText.primary,
    margin: 0,
    padding: 0,
  },
  challenge: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 'fit-content',
    marginBottom: 32,
    [media.TABLET]: {
      flexDirection: 'column',
    },
    inputSection: {
      display: 'flex',
      minHeight: 'fit-content',
      flexDirection: 'column',
      flexBasis: '70%',
      flexGrow: 1,
    },
    savingSection: {
      display: 'flex',
      flexBasis: '30%',
      minHeight: 'fit-content',
      flexGrow: 1,
      alignItems: 'flex-start',
      [media.TABLET]: {
        alignItems: 'stretch',
      },
      holder: {
        flexGrow: 1,
        backgroundColor: colors.primary.DarkPurple,
        textAlign: 'center',
        borderRadius: 5,
        marginLeft: 20,
        padding: 10,
        color: colors.secondary.white,
        [media.TABLET]: {
          marginLeft: 0,
          borderRadius: 0,
        },
        title: {
          ...gstyles.fonts.p4,
          ...gstyles.fonts.light,
          marginTop: 15,
          marginBottom: 15,
          marginLeft: 0,
          marginRight: 0,
          padding: 0,
        },
        text: {
          ...gstyles.fonts.p8,
          ...gstyles.fonts.light,
          marginTop: 15,
          marginBottom: 15,
          marginLeft: 0,
          marginRight: 0,
          padding: 0,
        },
        saving: {
          ...gstyles.fonts.p2,
          ...gstyles.fonts.semibold,
          margin: 0,
          padding: 0,
        },
      },
    },
    icon: {
      marginRight: 10,
      fill: colors.secondary.pink,
      minWidth: 24,
    },
  },
};
