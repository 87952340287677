import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router';

import CBCChallenge from 'redux/modules/CBCChallenge/container';

const styles = {
  closeButton: {
    float: 'right',
  },
};

const CBCChallengeDialog = ({ open, history }) => (
  <Dialog
    fullWidth
    open={open}
    onClose={() =>
      history.push(`${history.location.pathname}${history.location.search}`)
    }
    maxWidth="lg"
  >
    <DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() =>
          history.push(`${history.location.pathname}${history.location.search}`)
        }
        style={styles.closeButton}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <CBCChallenge />
    </DialogContent>
  </Dialog>
);

CBCChallengeDialog.propTypes = {
  open: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

export default withRouter(Radium(CBCChallengeDialog));
