import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  container: {
    position: 'fixed',
    top: theme.spacing(2),
    zIndex: '1000',
    margin: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  persistentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'static',
    transform: 'none',
    width: 'auto',
    marginBottom: theme.spacing(2),
  },
  notifications: {},
  notification: {
    position: 'static',
    transform: 'none',
    width: 'auto',
    maxWidth: '70ch',
    '& + &': {
      marginTop: theme.spacing(1),
    },
  },
  notificationMessage: {
    display: 'flex',
    alignItems: 'center',
    columnGap: `${theme.spacing(1)}px`,
  },
});

export default makeStyles(style);
