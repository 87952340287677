import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Button } from '@material-ui/core';
import SnackbarContent from 'components/modules/Shared/SnackbarContent';
import useStyles from './styles';

const NotificationOverlay = ({
  messages,
  persistentMessage,
  onClose,
  autoHideDuration,
}) => {
  const classes = useStyles();

  const handleClose = (e, reason, id) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose(id);
  };

  return (
    <div className={classes.container}>
      {persistentMessage && (
        <div className={classes.persistentContainer}>
          <Snackbar open classes={{ root: classes.notification }}>
            <SnackbarContent
              message={persistentMessage.message}
              variant={persistentMessage.variant}
              classes={{
                message: classes.notificationMessage,
              }}
            />
          </Snackbar>
        </div>
      )}
      <div className={classes.notifications}>
        {messages.slice(0, 5).map((m) => {
          const autoClose = m.autoClose === false ? false : true;

          return (
            <Snackbar
              key={m.id}
              open
              autoHideDuration={autoClose ? autoHideDuration : undefined}
              disableWindowBlurListener
              classes={{ root: classes.notification }}
              onClose={(...args) => handleClose(...args, m.id)}
            >
              <SnackbarContent
                message={m.message}
                variant={m.variant}
                classes={{ message: classes.notificationMessage }}
                action={
                  !autoClose && (
                    <Button onClick={(e) => handleClose(e, 'manual', m.id)}>
                      Close
                    </Button>
                  )
                }
              />
            </Snackbar>
          );
        })}
      </div>
    </div>
  );
};

NotificationOverlay.defaultProps = {
  persistentMessage: null,
  autoHideDuration: 6000,
};

NotificationOverlay.propTypes = {
  onClose: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number,
  persistentMessage: PropTypes.shape({
    message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    variant: PropTypes.oneOf(['default', 'success', 'error']),
  }),
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      autoClose: PropTypes.bool,
      variant: PropTypes.oneOf(['default', 'success', 'error']),
    })
  ).isRequired,
};

export default NotificationOverlay;
